.login-container {
  display: flex;
  height: 100vh;
}

.login-image {
  flex: 1;
  background-color: #f0f8ff; /* Light blue background */
  /* Add your illustration as a background image here */
  background-image: url('../../images/einvoice_login_image.jpg');
  background-position: center;
}

.login-form {
  flex: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo {
  color: #00a86b; /* Green color for the logo */
  margin-bottom: 2rem;
}

.social-logins button {
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.facebook { background-color: #3b5998; color: white; }
.twitter { background-color: #1da1f2; color: white; }
.google { background-color: #db4437; color: white; }

.or {
  text-align: center;
  margin: 1rem 0;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #00a86b;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.signup-link {
  text-align: center;
  margin-top: 1rem;
}

.signup-link a {
  color: #00a86b;
  text-decoration: none;
}

.error-message {
  color: red;
  margin-bottom: 1rem;
}